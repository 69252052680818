<template>
  <div
    :class="'notification-item translate ' + uniqueClass"
    v-on:click="open()"
  >
    <div class="logo">
      <div
        :style="
          'background-image: url(' +
          server +
          '/storage/tokens/' +
          data?.signal?.logo +
          ')'
        "
      ></div>
    </div>

    <div class="text">
      <div class="top">
        <span class="title notranslate"
          ><span class="signal-type">[{{ signal_type }}]</span>
          {{ data?.signal?.market }}</span
        >
        <span class="time">{{
          $filters.signalTimeLT(data.created_at, momentJsLocale)
        }}</span>
      </div>
      <div class="bottom">
        <span class="description">
          {{ data?.signal_update_event }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { openOutline } from "ionicons/icons";
// import { IonIcon } from "@ionic/vue";

export default {
  inject: ["store"],
  props: {
    data: {
      type: Object
    },
    key_: {
      default: null
    },
    uniqueClass: {
      default: null
    },
    showType: {
      type: Boolean,
      default: true
    }
  },
  components: {
    // IonIcon,
  },

  data() {
    return {
      openIcon: openOutline,
    };
  },

  computed: {
    server() {
      return this.store.server;
    },

    exchange() {
      if (this.data.type == "sell_signal") {
        let signal = JSON.parse(this.data.data);
        return signal.exchange;
      } else {
        return null;
      }
      // return null;
    },

    signal_type() {
      let type = null;
      switch (this.data.channel_id) {
        case "1429229352":
          type = this.lang?.change ?? "Spot";
          break;
        case "1440870181":
          type = this.lang?.change ?? "Future";
          break;
        case "1193828377":
          type = this.lang?.change ?? "High leverage";
          break;
      }
      return type;
    },
  },

  methods: {
    open() {
      // let signal = {
      //   data: JSON.parse(this.data?.signal),
      // };
      this.store.setters.signalInView({ data: this.data?.signal });
      this.$router.push("/signal");
    },
  },

  mounted() {
    // console.log({
    //   "signal-notification": this.data,
    // });
  },

  created() {
  }
};
</script>

<style scoped lang="scss">
.notification-item {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;

  .logo {
    flex: 0 1 40px;
    background: #f8f8f8;
    border-radius: 20px;

    > div {
      width: 40px;
      height: 40px;
      background-size: cover;
      border-radius: 20px;
    }

    ion-thumbnail {
      --size: 30px;
      --border-radius: 20px;

      > div {
        width: 30px;
        height: 30px;
        background-size: cover;
        border-radius: 20px;
      }
    }
  }

  .text {
    width: 100%;
    padding-left: 10px;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      .title {
        font-weight: bold;
        color: #000;

        .signal-type {
          color: $tetiary-color;
        }
      }

      .time {
        font-size: calc(14px + $extra-font-size);
        font-weight: 500;
        color: #848298;
      }
    }

    .bottom {
      color: #000;
      text-align: left;
      .description {
        font-size: calc(14px + $extra-font-size);

        .exchange {
          color: var(--ion-color-primary);
          font-weight: bold;
        }
      }
    }
  }
}
</style>