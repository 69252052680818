<template>
  <notification-list-2
    itemName="notification"
    listName="all_notifications"
    parentElementClass="notifications_scroll"
    loadUrl="/all-notifications-with-general"
    ref="List"
  ></notification-list-2>
</template>

<script>
import NotificationList2 from '@/views/components/cat-3/NotificationList2';

export default {
  components: {
    "notification-list-2": NotificationList2,
  },

  methods: {
    init() {
      this.$refs.List.init();
    }
  },

  mounted() {
    // console.log("All notifs mounted");
  },
};
</script>

<style>
</style>