<template>
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'home'"
    :toolbarColor="'primary'"
    :headerClass="'fixed-home'"
    :pageDefaultBackLink="'/home'"
    :pageTitle="lang.notifications_page.title"
    :contentColor="'transparent'"
    :selectLanguage="true"
  >
    <template v-slot:actions-end>
      <ion-button router-link="/settings" v-if="!browser">
        <ion-icon slot="icon-only" :icon="settingsSharp"></ion-icon>
      </ion-button>
    </template>

    <template v-slot:ion-content>
      <div class="curve-bars">
        <div class="light-bar"></div>
        <div class="dark-bar"></div>
      </div>
      <div class="page-container ion-padding notifications_scroll">
        <all-notifications
          ref="allNotifications"
          @interfaces="getInterfaces('allNotifications', $event)"
          @updateToggle="newNotificationToggle"
          :updateProp="newNotification"
        ></all-notifications>

        <transition name="fade">
          <ion-fab
            class="news-ion-fab scroll-up"
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            v-if="newNotification"
            v-on:click="softReset"
          >
            <ion-fab-button class="ion-fab-button">
              <ion-icon :icon="arrowUp"></ion-icon>
            </ion-fab-button>
          </ion-fab>
        </transition>
      </div>
    </template>
  </base-layout-2>
</template>


<script>
import { IonButton, IonIcon, IonFab, IonFabButton } from "@ionic/vue";
import { settingsSharp, arrowUp } from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";
import AllNotificationsVue from "../components/cat-3/AllNotifications.vue";

export default {
  inject: ["store"],
  props: [
    "pageTitle",
    "noPageHeader",
    "toolbarClass",
    "headerClass",
    "pageDefaultBackLink",
  ],
  data() {
    return {
      settingsSharp,
      arrowUp,
      signals: [],
      perRequest: 20,
      requests: 0,
      noMoreSignals: false,
      loading: true,
      create: false,
      created: false,
      newNotification: false,
      listActionTrigger: false,
      listActionHandler: null,
    };
  },
  components: {
    IonButton,
    IonIcon,
    IonFab,
    IonFabButton,
    "base-layout-2": BaseLayout2,
    "all-notifications": AllNotificationsVue,
  },

  //Storing interface for methods in child components.
  childInterface: {
    allNotifications: {},
  },

  methods: {
    update() {
      this.$options.childInterface.allNotifications.update();
    },

    reactToNewNotification() {
      let self = this;
      self.update();
    },

    //React to when the value of newNotification is decided to be set true or false from NotificationList2 child component
    newNotificationToggle(bool) {
      if (bool) {
        this.newNotification = true;
      } else {
        this.newNotification = false;
      }
    },

    //Set a child component's interfaces emitted from its own child component
    getInterfaces(slide, interfaces) {
      this.$options.childInterface[slide] = interfaces;
    },

    goToSpots() {
      this.$router.push({
        path: "/spots",
      });
    },

    softReset() {
      this.$options.childInterface.allNotifications.softReset();
    },

    hardReset() {
      let self = this;
      self.$options.childInterface.allNotifications.hardReset();
    },

    loadNotifications(start, size) {
      let self = this;
      let filter = self.store.getters.signalFilter();

      let data = {
        start: start,
        slice: size,
        filter,
        device_id: self.store.state.deviceID,
      };

      return window.axios({
        url: self.store.endpoint + "/notifications",
        method: "post",
        data: data,
      });
    },

    init() {
      let self = this;
      if (!self.created) {
        self.$refs.allNotifications.init();
        self.created = true;
        self.store.actions.triggerCompAction("notification_seen", null);
        self.store.setters.reloadOnEnter("notifications", false);
      } else {
        if (self.store.state.reloadOnEnter.notifications) {
          self.hardReset();
          self.store.actions.triggerCompAction("notification_seen", null);
          self.store.setters.reloadOnEnter("notifications", false);
        }
      }
    },
  },

  computed: {
    compActionTrigger() {
      return this.store.state.compActionTrigger;
    },
  },

  watch: {
    compActionTrigger() {
      let action = this.store.state.compAction;
      let self = this;

      switch (action.type) {
        //There is a new spot signal
        case "new_notification":
          self.reactToNewNotification();
          break;
      }
    },
  },

  ionViewDidEnter() {
    this.init();
    window.googleTranslateSelectTag.value = this.googleTranslateLocale;
    window.googleTranslateSelectTag.dispatchEvent(new Event("change"));

    let self = this;
    setTimeout(() => {
      console.log({
        childInterfaceNOtifications: self.$options.childInterface,
      });
    }, 4000);
  },

  created() {},
};
</script>


<style scoped lang="scss">
.page-container {
  padding-top: 20px;
  border-top-left-radius: 40px;
  background: #fafafc;
  overflow: auto;
  position: relative;
  @include toolbar-content-size-1;
}

ion-fab.news-ion-fab {
  position: fixed;
  left: calc(50% - 28px);
  top: 100px;
  width: fit-content;
  height: fit-content;

  ion-fab-button.ion-fab-button {
    --background: #{lighten($primary-color, 70%)};
  }

  ion-icon {
    color: $primary-color;
  }
}
</style>