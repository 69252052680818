<template>
  <infinite-scroll-recycler
    :size="size"
    :loadData="loadData"
    :itemMargin="itemMargin"
    :bgColor="bgColor"
    :itemUniqueClass="notificationItemUniqueClass"
    ref="recycler"
  >
    <template v-slot:item="props">
      <notification-item
        :data="props.data"
        :key_="props.key_"
        :uniqueClass="notificationItemUniqueClass"
        :showType="showType"
      ></notification-item>
    </template>

    <template v-slot:tombstone="props">
      <tombstone :loading="props.loading"></tombstone>
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </infinite-scroll-recycler>
</template>

<script>
import InfiniteScrollRecycler from "./InfiniteScrollRecycler.vue";
import Tombstone from "./NotificationTombstone.vue";
import NotificationItem from '@/views/components/cat-3/NotificationItem'

export default {
  props: {
    loadData: {
      default: null,
    },
    notificationItemUniqueClass: {
      type: String,
    },
    showType: {
      type: Boolean,
      default: true,
    }
  },
  inject: ["store"],
  components: {
    InfiniteScrollRecycler,
    Tombstone,
    NotificationItem,
  },

  data() {
    return {
      size: 40, //Whole even number
      itemMargin: 30,
      bgColor: "#fafafc",
    };
  },

  methods: {
    init() {
      this.$refs.recycler.init();
    },
  },
};
</script>

<style scoped>
</style>