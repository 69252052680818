<template>
  <div style="display: contents">
    <notification-item-signal
      :data="data"
      v-if="data.type == 'signal_update'"
      :showType="showType"
      :uniqueClass="uniqueClass"
    ></notification-item-signal>

    <notification-item-general
      :data="data"
      v-if="data.type == 'general_notification'"
      :showType="showType"
      :uniqueClass="uniqueClass"
    ></notification-item-general>
  </div>
</template>

<script>
import NotificationItemGeneral from "@/views/components/cat-3/NotificationItemGeneral";
import NotificationItemSignal from "@/views/components/cat-3/NotificationItemSignal";

export default {
  inject: ["store"],

  props: {
    data: {
      type: Object,
    },

    key_: {
      default: null,
    },

    uniqueClass: {
      type: String,
    },

    showType: {
      type: Boolean,
      default: true,
    },

  },

  components: {
    NotificationItemGeneral,
    NotificationItemSignal
  },

  computed: {
    server() {
      return this.store.server;
    },
  },

  mounted() {
    // console.log({
    //   data: this.data
    // })
  },

  created() {
  }
};
</script>