<template>
  <div :class="'notification-item ' + uniqueClass">
    <template v-if="data">
      <div class="title-text">
        <div class="top">
          <span class="title"
            ><span class="notification-type" v-if="showType"
              >[{{ notification_type }}]</span
            >
            {{ title }}</span
          >
          <span class="time">{{
            $filters.signalTimeLT(data.created_at, momentJsLocale)
          }}</span>
        </div>
        <div class="bottom">
          <span class="description">
            {{ data.signal_update_event }}
          </span>
        </div>
      </div>
      <div
        class="image-wrap"
        v-if="data.general_notification_image"
        @click="open(data)"
      >
        <div
          class="image-background"
          :style="
            'background-image: url(' +
            server +
            '/storage/notification_images/' +
            data.general_notification_image +
            ')'
          "
        ></div>
        <img
          class="image"
          :src="
            server +
            '/storage/notification_images/' +
            data.general_notification_image
          "
          alt=""
        />
        <span class="expand">
          <ion-icon :icon="expand"></ion-icon>
        </span>
      </div>
      <div
        class="body-text"
        v-if="data.general_notification_text"
        @click="toggleFullBody()"
      >
        {{ body }}
      </div>
    </template>
  </div>
</template>

<script>
import { openOutline, expand } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";

export default {
  inject: ["store"],
  props: {
    data: {
      type: Object,
    },
    key_: {
      default: null,
    },
    uniqueClass: {
      default: null,
    },
    showType: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    IonIcon,
  },

  data() {
    return {
      openIcon: openOutline,
      expand,
      showFullBody: false,
    };
  },

  computed: {
    server() {
      return this.store.server;
    },

    notification_type() {
      let type = null;
      switch (this.data.channel_id) {
        case "1429229352":
          type = this.lang?.spot ?? "Spot";
          break;
        case "1440870181":
          type = this.lang?.future ?? "Future";
          break;
        case "1193828377":
          type = this.lang?.high_leverage ?? "High leverage";
          break;
      }
      return type;
    },

    title() {
      let title = this.data.general_notification_text;
      return title ? title.substring(0, 7) + "..." : "...";
    },

    body() {
      let text = this.data.general_notification_text;
      if (text.length > 100 && !this.showFullBody) {
        return text.substring(0, 200) + "...";
      } else {
        return text;
      }
    },
  },

  methods: {
    open(data) {
      this.store.setters.generalNotificationInView(data);
      this.$router.push("/general-notification");
    },

    toggleFullBody() {
      this.showFullBody = !this.showFullBody;
    },

    // async getTestData() {
    //   let resp = await window.axios({
    //     url: "http://192.168.137.1:8000/api/get-test-data",
    //     method: "get",
    //   });

    //   if (resp.status == 200) {
    //     this.data = resp.data;
    //   }
    // },
  },

  mounted() {
    let self = this;

    self.emitter.on("minimize-text", () => {
      self.showFullBody = false;
    });
  },

  created() {
    // this.getTestData();
  },
};
</script>

<style scoped lang="scss">
.notification-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #bbb;
  margin-bottom: 30px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  color: #000;
  text-align: left;

  .title-text {
    width: 100%;
    padding-left: 10px;
    margin-bottom: 10px;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      .title {
        font-weight: bold;

        .notification-type {
          color: $tetiary-color;
        }
      }

      .time {
        font-size: calc(14px + $extra-font-size);
        font-weight: 500;
        color: #848298;
      }
    }

    .bottom {
      .description {
        font-size: calc(14px + $extra-font-size);

        .exchange {
          color: var(--ion-color-primary);
          font-weight: bold;
        }
      }
    }
  }

  .image-wrap {
    width: 100%;
    height: 250px;
    background-color: #ccc;
    position: relative;
    margin-bottom: 10px;

    .image-background {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      filter: blur(6px);
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
      position: absolute;
      top: 0;
      left: 0;
    }

    .expand {
      position: absolute;
      bottom: 14px;
      right: 16px;
      font-size: calc(20px + $extra-font-size);
      color: #fff;
      background: rgba(0, 0, 0, 0.4);
      height: 24px;
      padding: 2px;
    }
  }

  .body-text {
    white-space: pre-line;
    font-size: calc(14px + $extra-font-size);
  }
}
</style>