<template>
  <div class="notification-item">
    <div class="logo">
      <div></div>
    </div>

    <div class="text">
      <div class="top">
        <span class="title">
          <ion-skeleton-text
            :animated="loading"
            style="width: 80px"
          ></ion-skeleton-text>
        </span>
        <span class="time"
          ><ion-skeleton-text
            :animated="loading"
            style="width: 50px"
          ></ion-skeleton-text
        ></span>
      </div>
      <div class="bottom">
        <span class="description"
          ><ion-skeleton-text
            :animated="loading"
            style="width: 150px"
          ></ion-skeleton-text
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { IonSkeletonText } from "@ionic/vue";

export default {
  props: {
    loading: {
      type: Boolean,
    },
  },
  components: {
    IonSkeletonText,
  },

  data() {
    return {};
  },

  mounted() {},
};
</script>

<style scoped lang="scss">
.notification-item {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
  padding-bottom: 10px;
  height: 55px;

  .logo {
    flex: 0 1 40px;
    background: #f5f5f5;
    border-radius: 20px;
    height: 40px;

    > div {
      width: 40px;
      height: 40px;
      background-size: cover;
      border-radius: 20px;
    }

    ion-thumbnail {
      --size: 30px;
      --border-radius: 20px;

      > div {
        width: 30px;
        height: 30px;
        background-size: cover;
        border-radius: 20px;
      }
    }
  }

  .text {
    width: 100%;
    padding-left: 10px;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      .title {
        font-weight: bold;
      }

      .time {
        font-size: calc(14px + $extra-font-size);
        font-weight: 500;
        color: #848298;
      }
    }

    .bottom {
      .description {
        font-size: calc(14px + $extra-font-size);
      }
    }
  }
}
</style>